import { RES_TYPES, useFetch } from "@chainless/chainless-react";
import { useEffect } from "react";
import { FaCheck, FaSave, FaTimes } from "react-icons/fa";

export const UpdateIdee = ({ id, refresh }) => {
  const { data: idee, fetchData: ideeFetch } = useFetch(`/api/ideeenbus/${id}`);
  const { res: patchIdeeRes, fetchData, error: patchError } = useFetch(`/api/ideeenbus/${id}`, { method: 'PATCH', resType: RES_TYPES.text, lateFetch: true });

  const ideeAfhandeling = (keuze) => {
    if (!window.confirm(`Weet u zeker dat u dit idee wilt ${keuze}?`)) {
      return;
    }
    fetchData(JSON.stringify({
      status: keuze
    }));
  };

  useEffect(() => {
    if (patchIdeeRes && patchIdeeRes.ok) {
      refresh();
      ideeFetch();
    } else if (patchError) {
      alert('Fout bij het updaten van een idee', patchError);
    }
  }, [patchIdeeRes, patchError]);

  if (idee && idee.ideestatus === 'IN_BEHANDELING') return (
    <div className="inhoud">
      <FaCheck className="update-icon" onClick={() => ideeAfhandeling('accepteren')} />
      <FaSave className="update-icon" onClick={() => ideeAfhandeling('bewaren')} />
      <FaTimes className="update-icon" onClick={() => ideeAfhandeling('afwijzen')} />
    </div>
  );
};