import { ChnAuth, ChnLayoutPage, ChnRoleCheck } from '@chainless/chainless-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import { MeldingVoorvalPage } from './pages/MeldingVoorvalPage';
import { NieuwsPage } from './pages/NieuwsPage';
import { VerlofPage } from './pages/VerlofPage';
import { VGMPage } from './pages/VGMPage';
import { IdeeenbusPage } from './pages/IdeeenbusPage';

const tabs = [
  {
    name: 'Melding Voorval',
    route: '/'
  },
  {
    name: 'Nieuws',
    route: '/nieuws'
  },
  {
    name: 'Verlof',
    route: '/verlof'
  },
  {
    name: 'Vgm',
    route: '/vgm'
  },
  {
    name: 'Ideeënbus',
    route: '/ideeenbus'
  }
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <ChnLayoutPage tabs={tabs} name={"ChApp Admin"} />,
    children: [
      {
        path: "/",
        element: <ChnRoleCheck allowedRoles={['chn.melding_beheer', 'chn.dev']}><MeldingVoorvalPage /></ChnRoleCheck>
      },
      {
        path: "nieuws",
        element: <ChnRoleCheck allowedRoles={['chn.nieuws_beheer', 'chn.dev']}><NieuwsPage /></ChnRoleCheck>
      },
      {
        path: "verlof",
        element: <ChnRoleCheck allowedRoles={['chn.verlof_beheer']}><VerlofPage /></ChnRoleCheck>
      },
      {
        path: "vgm",
        element: <ChnRoleCheck allowedRoles={['chn.melding_beheer', 'chn.dev']}><VGMPage /></ChnRoleCheck>
      },
      {
        path: 'ideeenbus',
        element: <ChnRoleCheck allowedRoles={['chn.nieuws_beheer', 'chn.dev']}><IdeeenbusPage /></ChnRoleCheck>
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChnAuth>
      <RouterProvider router={router} />
    </ChnAuth>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
// reportWebVitals(console.log);
