import { ChnSortSearchTable, useFetch } from "@chainless/chainless-react";
import { Responses } from "../components/Responses";
import { UpdateIdee } from "../components/UpdateIdee";
import { ImagePopup } from "../components/ImagePopup";

export const IdeeenbusPage = () => {
  const { data: ideeen, loading, error, fetchData } = useFetch('/api/ideeenbus');

  const refreshAll = () => {
    fetchData();
  };

  const headers = [
    { key: 'timestamp', name: 'Datum idee', valueMapper: (timestamp) => new Date(timestamp).toLocaleDateString('nl-NL') },
    { key: 'ideestatus', name: 'Status', valueMapper: (v) => v },
    { key: 'author', name: 'Auteur', valueMapper: (v) => v },
    { key: 'title', name: 'Titel', valueMapper: (v) => v },
    { key: 'omschrijving', name: 'Omschrijving', valueMapper: (v) => v },
    { key: 'foto', name: 'Foto', valueMapper: (f) => f ? <ImagePopup src={`/api/images/${f}`} /> : '', search: false },
    { key: 'votes', name: 'Stemmen', valueMapper: (votes) => <Responses r={votes} />, search: false },
    { key: 'id', name: 'Change', valueMapper: (id) => <UpdateIdee id={id} refresh={refreshAll} />, search: false }
  ];


  return (
    <>
      {loading && <p>Ideeën ophalen...</p>}
      {error && <p>Error bij ophalen ideeën: {error}</p>}
      {ideeen && ideeen.length > 0 &&
        <>
          <div className="inhoud">
            <div className="tabel" style={{ minHeight: '55vh' }}>
              <h1>Alle ideeën</h1>
              <div className="table">
                <ChnSortSearchTable headers={headers} data={ideeen} pageSize={10} />
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};